.containerBotonesPago{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.botonBinance{
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 240px;
	height: 100px;
	background: #404040;
	border-radius: 0.5rem;
	cursor: pointer;
}

.botonBinance:hover{
	background: #1c1917;
}

.logoBinance{
	width: 45px;
	margin-right: 10px;
}

.textoBinance{
	color: #dbe5e5;
}

.botonBanco{
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 240px;
	height: 100px;
	background: #075985;
	border-radius: 0.5rem;
	cursor: pointer;
	margin-left: 10px;
}

.botonBanco:hover{
	background: #07598599;
}

.textoBanco{
	color: #fff;
}

.bordePago{
	border: 1px solid #3ad32b;
}

@media(max-width: 512px){
	.containerBotonesPago{
		justify-content: center;
		align-items: center;
	}
	.botonBinance{
		margin: 10px !important;
	}
	.botonBanco{
		margin: 10px !important;
	}
}