.botonesAuth{
	text-align: center;
}

.menuUsuario{
	display: flex;
	flex-direction: row;
	align-items: center;
}

.cantidadSaldoMenu{
	color: #d4d4d4;
}

.ticket{
	width: 30px;
}

.imgUsuario{
	width: 60px;
	height: 60px;
	border-radius: 100px;
	margin-left: 10px;
	object-fit: cover;
	cursor: pointer;
}

.opcionesUsuario{
	background: #404040;
    width: 150px;
    height: 200px;
    position: absolute;
    right: 0;
    margin-right: 20px;
    display: none;
    z-index: 99;
}

.opcionUsuario{
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 5px;
	cursor: pointer;
	text-decoration: none;
}

.opcionUsuario:hover{
	background: #3ad32b99;
}

.containerImgOpcionUsuario{
	width: 40px;
	height: 40px;
	background: rgb(58, 211, 43);
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	border-radius: 100%;
	margin-right: 10px;
}

.imgOpcionUsuario{
	width: 20px;
}

.textoOpcionUsuario{
	color: #d4d4d4;
}

.heightAuto{
	height: auto;
}