.clientes{
	width: 100%;
	min-height: 320px;
	background: #171717;
	border-radius: 10px;
	padding: 20px;
}

.perfilCliente{
	background: #404040;
	padding: 10px;
	border-radius: 10px;
	display: inline-block;
	margin: 10px;
}