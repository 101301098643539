.productosHome{
	width: 100%;
	/*min-height: 100vh;*/
	background: #171717;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
}

.producto{
	width: 280px;
    height: fit-content;
    border-radius: 0.5rem;
    margin: 20px;
    padding: 10px;
    transition: all .2s ease-in-out;
}

.producto:hover{
	transform: scale(1.1);
}

.estrellas{
	color: #ffcd00;
	font-size: 24px;
	text-align: center;
}

.textoCantidadVendido{
	padding: 5px;
	text-align: center;
	border-radius: 5px;
	background: #00000099;
	color: #fff;
	width: 90%;
	margin: auto;
}

.imgProducto{
	width: 150px;
    height: 150px;
    border-radius: 100%;
    display: block;
    margin: auto;
    margin-top: 10px;
}

.precioDescuentoContainer{
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.precio{
	width: 100px;
	height: 40px;
	text-align: center;
	border-radius: 5px;
	color: #fff;
	font-size: 24px;
	font-weight: bold;
}

.descuento{
	font-size: 24px;
	font-weight: bold;
	color: red;
}

.tituloBusqueda{
	color: #fff;
	font-weight: bold;
	text-align: center;
}

.modalComprarProducto{
	width: 60vw;
	padding: 10px;
}

.productoCompra{
	width: 100%;
	padding: 10px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	color: #fff;
}

.detallesProducto{
	width: 100%;
	padding: 10px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	background: #171717;
	border: 1px solid rgb(58, 211, 43);
	margin-bottom: 20px;
}

.elDetalleProducto{
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-right: 10px;
	margin-left: 10px;
}

.imagenDetalleProducto{
	width: 60px;
	height: 60px;
	background: #262626;
	display: flex;
	justify-content: center;
	align-items: center;
}

.imgDetalleProducto{
	width: 30px;
}

.tituloDetalleProducto{
	color: #fff;
	margin-left: 10px;
}

.opcionesProducto{
	width: 100%;
	padding: 10px;
	background: #171717;
	border: 1px solid rgb(58, 211, 43);
	margin-bottom: 20px;
}

.tituloOpcionesProducto{
	color: #fff;
	font-weight: bold;
}

.hr{
	width: 100%;
	height: 1px;
	background: rgb(58, 211, 43);
	margin-bottom: 10px;
}

.opcionesElegir{
	width: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	/*justify-content: space-between;*/
}

.laOpcionComprar{
	background: #262626;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	cursor: pointer;
	padding: 10px;
	width: 300px;
    margin: 10px;
}

.laOpcionComprar:hover{
	background: rgb(58, 211, 43, 0.5);
}

.laOpcionComprarActive{
	background: rgb(58, 211, 43);
}

.form-check-input:checked {
    background-color: #3ad32b !important;
    border-color: #3ad32b !important;
    scale: 1.5 !important;
}

.vistaImgCategoria{
	width: 200px;
	height: 370px;
	border-radius: 5px;
	margin: 10px;
	cursor: pointer;
	object-fit: cover;
}

.urlCat{
	width: 200px;
	height: 370px;
	margin: 10px;
}

.vistaImgCategoria:hover{
	-webkit-filter: contrast(150%);
	filter: contrast(150%);
	-webkit-transform: translate(0, 0) rotate(0) skewX(0) skewY(0) scaleX(105%) scaleY(105%);
	transform: translate(0, 0) rotate(0) skewX(0) skewY(0) scaleX(105%) scaleY(105%);
}

#wrapper{
	    position:relative;
	    /*background:#333;*/
	    height:100%;
	}

	.profile-main-loader{
	    left: 50% !important;
	    /* margin-left: -100px; */
	    /* position: fixed !important; */
	    top: 50% !important;
	    /* margin-top: -100px; */
	    /* width: 45px; */
	    z-index: 9000 !important;
	    height: 100vh;
	    display: flex;
	    justify-content: center;
	    align-items: center;
	}

	.profile-main-loader .loader {
	  position: relative;
	  margin: 0px auto;
	  width: 200px;
	  height:200px;
	}
	.profile-main-loader .loader:before {
	  content: '';
	  display: block;
	  padding-top: 100%;
	}

	.circular-loader {
	  -webkit-animation: rotate 2s linear infinite;
	          animation: rotate 2s linear infinite;
	  height: 100%;
	  -webkit-transform-origin: center center;
	      -ms-transform-origin: center center;
	          transform-origin: center center;
	  width: 100%;
	  position: absolute;
	  top: 0;
	  left: 0;
	  margin: auto;
	}

	.loader-path {
	  stroke-dasharray: 150,200;
	  stroke-dashoffset: -10;
	  -webkit-animation: dash 1.5s ease-in-out infinite, ease-in-out infinite;
	          animation: dash 1.5s ease-in-out infinite, ease-in-out infinite;
	  stroke-linecap: round;
	}

	@-webkit-keyframes rotate {
	  100% {
	    -webkit-transform: rotate(360deg);
	            transform: rotate(360deg);
	  }
	}

	@keyframes rotate {
	  100% {
	    -webkit-transform: rotate(360deg);
	            transform: rotate(360deg);
	  }
	}
	@-webkit-keyframes dash {
	  0% {
	    stroke-dasharray: 1,200;
	    stroke-dashoffset: 0;
	  }
	  50% {
	    stroke-dasharray: 89,200;
	    stroke-dashoffset: -35;
	  }
	  100% {
	    stroke-dasharray: 89,200;
	    stroke-dashoffset: -124;
	  }
	}
	@keyframes dash {
	  0% {
	    stroke-dasharray: 1,200;
	    stroke-dashoffset: 0;
	  }
	  50% {
	    stroke-dasharray: 89,200;
	    stroke-dashoffset: -35;
	  }
	  100% {
	    stroke-dasharray: 89,200;
	    stroke-dashoffset: -124;
	  }
	}
	@-webkit-keyframes color {
	  0% {
	    stroke: #70c542;
	  }
	  40% {
	    stroke: #70c542;
	  }
	  66% {
	    stroke: #70c542;
	  }
	  80%, 90% {
	    stroke: #70c542;
	  }
	}
	@keyframes color {
	  0% {
	    stroke: #70c542;
	  }
	  40% {
	    stroke: #70c542;
	  }
	  66% {
	    stroke: #70c542;
	  }
	  80%, 90% {
	    stroke: #70c542;
	  }
	}

#categoriasMovil{
	display: none;
}

@media(max-width: 512px){
	.modalComprarProducto{
		width: 100%;
	}
	.elDetalleProducto {
	    margin-bottom: 10px;
	}
	#categoriasMovil{
		display: flex;
	}
	#categoriasCompleto{
		display: none;
	}
}