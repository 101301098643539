.footer{
	width: 100%;
	padding: 20px;
	background: #262626;
	border: 0px solid rgb(58, 211, 43);
    border-top-width: 1px;
}

.filaFooter{
	width: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
}

.textoDerechos{
	color: rgb(163 163 163);
}