.containerTituloSeccion{
	background: #171717;
	width: 100%;
	height: 100px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	border: 1px solid #3ad32b;
}

.imgTituloSeccion{
	width: 30px;
	margin-right: 20px;
	margin-left: 20px;
}

.tituloSeccion{
	color: #e5e5e5;
}