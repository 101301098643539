.gridApp{
	width: 100%;
	min-height: 100vh;
	display: flex;
	flex-direction: row;
	background: #262626;
}

@media(max-width: 998px){
	.gridApp{
		flex-wrap: wrap;
	}
}