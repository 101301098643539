.dataStock{
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.bandera{
	width: 60px;
    height: 60px;
    border-radius: 100%;
    object-fit: cover;
    border: 5px solid #fff;
    position: absolute;
    margin-left: 150px;
    margin-top: -40px;
}