.containerFotoPerfil{
	width: 320px;
	display: block;
	margin: auto;
	margin-top: 5%;
	text-align: left;
}

.containerCamPerfil{
	width: 40px;
    height: 40px;
    background: #383838;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    z-index: 1;
    position: absolute;
    cursor: pointer;
    border: 1px solid #3ad32b;
}

.camPerfil{
    width: 20px;
}

.imgUsuarioPerfil{
	width: 220px;
	height: 220px;
	border-radius: 100%;
	position: absolute;
	margin-top: -20px;
    z-index: 0;
    object-fit: cover;
}

.containerNombreEmail{
	width: 320px;
	display: block;
	margin: auto;
	margin-top: 250px;
	padding: 10px;
	background: #383838;
	border: 1px solid #3ad32b;
}