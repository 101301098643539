.modalFondo{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #00000099;
}

.contenidoModal{
	position: relative;
	background: #262626;
	border-radius: 20px;
	z-index: 10;
	min-width: 320px;
	padding: 20px;
    text-align: center;
}

.equix{
	text-align: right;
	color: red;
	font-weight: bold;
	cursor: pointer;
}

@media(max-width: 512px){
	.modalFondo{
		overflow: auto;
	}
	.contenidoModal{
		margin-top: 512px;
	}
}