.containerAuth{
	width: 100%;
	height: 100vh;
	background: #171717;
}

.form{
	display: block;
	margin: auto;
	background: #262626;
	padding: 20px;
	margin-top: 10%;
	max-width: 512px;
	border: 1px solid #3ad32b;
}

.headerFormLogin{
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.userPlus{
	width: 45px;
}

.tituloHeaderLogin{
	color: #a3a3a3;
}

.containerInput{
	background: #404040;
	border-radius: 5px;
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: 20px; 
	border: 1px solid #3ad32b;
}

.imgLabel{
	width: 28px;
	margin-left: 5px;
	margin-right: 5px;
}

.inputLogin{
    border: 0px solid transparent;
}

.inputLogin:focus{
	outline: none;
}

.imgBoton{
	width: 32px;
}

.textoOpcionesLogin{
	color: #a3a3a3;
    margin-top: 40px;
    text-align: center;
}

.opcionLogin{
	color: #c6e5e5;
	cursor: pointer;
	font-weight: bold;
	text-decoration: none;
}

div:where(.swal2-container).swal2-center>.swal2-popup {
    background: #404040;
}

div:where(.swal2-container) h2:where(.swal2-title) {
    color: #fff !important;
}

.inputTelefono{
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}