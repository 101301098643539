.menuApp{
	width: 100%;
	padding: 10px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	background: #262626;
	flex-wrap: wrap;
}

.iconoYBuscador{
	display: flex;
	flex-direction: row;
	align-items: center;
}

.logoMenu{
	width: 180px;
	display: inline-block;
	margin-right: 20px;
	cursor: pointer;
}

.MenuAdmin{
	width: 320px;
	padding: 20px;
	background: #171717;
	height: 100vh;
}

.opcionMenu{
	width: 100%;
	height: 50px;
	background: #262626;
	display: flex;
	flex-direction: row;
	align-items: center;
	border-radius: 5px;
	cursor: pointer;
	color: #fff;
	padding: 10px;
	margin-top: 20px;
	border: 1px solid #3ad32b;
	text-decoration: none;
}

.opcionMenu:hover{
	background: #525252;
}

.imgOpcionMenu{
	width: 20px;
    margin-right: 10px;
}

.listaBusqueda{
	width: 215px;
    max-height: 370px;
    overflow: auto;
    background: #fff;
    position: absolute;
    z-index: 99;
    margin-top: -15px;
}

.productoBusqueda{
	width: 100%;
	padding: 10px;
	display: flex;
	flex-direction: row;
	align-items: center;
	cursor: pointer;
	color: #000;
	text-decoration: none;
}

.productoBusqueda:hover{
	background: rgb(58, 211, 43);
	color: #fff;
}

.fotoProductoBusqueda{
	width: 60px;
	height: 60px;
	border-radius: 100%;
	margin-right: 10px;
}

.nombreProductoBusqueda{
	/*color: #000;*/
}

.menuResponsive{
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	cursor: pointer;
}

.iconoMenu{
	width: 40px;
	margin: 10px;
}

.textoNuevoMenu{
	color: #fff;
	font-weight: bold;
}

@media(min-width: 999px){
	.menuResponsive{
		display: none;
	}
}

@media(max-width: 998px){
	.MenuAdmin{
		width: 100%;
	}
	#MenuAdmin{
		display: none;
	}
	.col-sm{
		overflow: auto !important;
	}
}